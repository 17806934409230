const type_map = {
  vegetable: '菜',
  meat: '肉',
  soup: '汤',
};
const rate_map = {
  1: '🍚',
  2: '🍚 🍚',
  3: '🍚 🍚 🍚',
  4: '🍚 🍚 🍚 🍚',
  5: '🍚 🍚 🍚 🍚 🍚',
};
const difficulty_map = {
  1: '⭐',
  2: '⭐ ⭐',
  3: '⭐ ⭐ ⭐',
  4: '⭐ ⭐ ⭐ ⭐',
  5: '⭐ ⭐ ⭐ ⭐ ⭐',
};
function formatRecipeType(recipe_type) {
  return type_map[recipe_type];
}
function formatRate(rate) {
  return rate_map[rate];
}
function formatDifficulty(difficulty) {
  return difficulty_map[difficulty];
}

const RECIPE_TYPES = [
        { key: 'meat', value: '肉' },
        { key: 'vegetable', value: '菜' },
        { key: 'soup', value: '汤' },
      ];
module.exports = {
  RECIPE_TYPE_VEGETABLE: 'vegetable',
  RECIPE_TYPE_MEAT: 'meat',
  RECIPE_TYPE_SOUP: 'soup',
  RECIPE_TYPES,
  formatRecipeType,
  formatDifficulty,
  formatRate,
}
