<template>
  <week_recipe />
  <tabbar />
</template>

<script>
import tabbar from '@/components/recipe-mobile/tabbar.vue';
import week_recipe from '@/components/recipe-mobile/week-recipe.vue';

export default {
  name: 'RecipeMobileWeekRecipe',
  components: {
    tabbar,
    week_recipe,
  },
  data() {
    return {};
  },
};
</script>
