<template>
  <el-table border :data="daily_recipe">
    <el-table-column prop="meat" label="肉">
      <el-select
        v-model="meat"
        multiple
        size="mini"
        placeholder="选个肉"
        @change="handleChange"
      >
        <el-option
          v-for="recipe in meat_recipe"
          :key="recipe.id"
          :label="recipe.name"
          :value="recipe.id"
        >
        </el-option>
      </el-select>
    </el-table-column>
    <el-table-column prop="vegetable" label="菜">
      <el-select
        v-model="vegetable"
        multiple
        size="mini"
        placeholder="选个菜"
        @change="handleChange"
      >
        <el-option
          v-for="recipe in vegetable_recipe"
          :key="recipe.id"
          :label="recipe.name"
          :value="recipe.id"
        >
        </el-option>
      </el-select>
    </el-table-column>
    <el-table-column prop="soup" label="汤">
      <el-select
        v-model="soup"
        multiple
        size="mini"
        placeholder="选个汤"
        @change="handleChange"
      >
        <el-option
          v-for="recipe in soup_recipe"
          :key="recipe.id"
          :label="recipe.name"
          :value="recipe.id"
        >
        </el-option>
      </el-select>
    </el-table-column>
  </el-table>
  <el-row gutter="20">
    <el-col :span="12">
      <el-button
        type="primary"
        plain
        class="re-generate"
        @click="reGenerateRecipe()"
      >
        重新生成
      </el-button>
    </el-col>
    <el-col :span="12">
      <el-button
        type="success"
        plain
        :disabled="disable_submit"
        class="re-generate"
        @click="submitRecipe()"
      >
        提交
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios';
import config from '@/config/index';
export default {
  name: 'DailyRecipeDetail',
  data: function() {
    return {
      daily_recipe: [],
      inputVisible: false,
      inputValue: null,
      meat_recipe: [],
      meat: [],
      vegetable_recipe: [],
      vegetable: [],
      soup_recipe: [],
      soup: [],
      disable_submit: true,
    };
  },
  mounted() {
    this.getDailyRecipe(),
      axios
        .get(
          config.publicPath + '/recipe/recipe/?recipe_type=meat&page_size=500'
        )
        .then((response) => (this.meat_recipe = response.data.results));
    axios
      .get(
        config.publicPath +
          '/recipe/recipe/?recipe_type=vegetable&page_size=500'
      )
      .then((response) => (this.vegetable_recipe = response.data.results));
    axios
      .get(config.publicPath + '/recipe/recipe/?recipe_type=soup&page_size=500')
      .then((response) => (this.soup_recipe = response.data.results));
  },
  methods: {
    reGenerateRecipe() {
      axios
        .post(
          config.publicPath + '/recipe/daily-recipe/' + this.$route.params.id
        )
        .then((response) => (response, this.getDailyRecipe()));
    },
    submitRecipe() {
      axios.put(
        config.publicPath + '/recipe/daily-recipe/' + this.$route.params.id,
        {
          meat: this.meat,
          vegetable: this.vegetable,
          soup: this.soup,
        }
      );
      this.disable_submit = true;
    },
    handleChange() {
      console.log(this.meat, this.vegetable, this.soup);
      this.disable_submit = false;
    },
    getDailyRecipe() {
      axios
        .get(
          config.publicPath + '/recipe/daily-recipe/' + this.$route.params.id
        )
        .then((response) => {
          var data = {
            meat: [],
            vegetable: [],
            soup: [],
          };
          this.meat = [];
          this.vegetable = [];
          this.soup = [];

          for (let i = 0; i < response.data.recipes.length; i++) {
            if (response.data.recipes[i].recipe_type == 'meat') {
              data.meat.push(response.data.recipes[i]);
              this.meat.push(response.data.recipes[i]['id']);
            } else if (response.data.recipes[i].recipe_type == 'vegetable') {
              data.vegetable.push(response.data.recipes[i]);
              this.vegetable.push(response.data.recipes[i]['id']);
            } else if (response.data.recipes[i].recipe_type == 'soup') {
              data.soup.push(response.data.recipes[i]);
              this.soup.push(response.data.recipes[i]['id']);
            }
          }
          this.daily_recipe = [data];
          this.disable_submit = true;
        });
    },
  },
};
</script>

<style></style>
