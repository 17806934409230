<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
    class="recipe-list"
  >
    <van-cell
      center
      v-for:="recipe in recipes"
      size="large"
      clickable
      :to="{
        name: 'RecipeMobileRecipeDetail',
        params: { id: recipe.id },
      }"
    >
      <template #title>
        <van-tag
          class="recipe-type-tag"
          plain
          v-bind:type="getTagType(recipe.recipe_type)"
          >{{ constants.formatRecipeType(recipe.recipe_type) }}</van-tag
        >
        <span class="recipe-name"> {{ recipe.name }}</span>
      </template>
      <template #value>
        {{ recipe.difficulty }} <van-icon name="star" /> · {{ recipe.rate }}
        <van-icon name="like" />
      </template>
    </van-cell>
  </van-list>
</template>

<script>
import { Cell, List, Tag, Collapse, CollapseItem, Icon } from 'vant';
import axios from 'axios';
import config from '@/config/index.js';
import constants from '@/utils/constants';

export default {
  name: 'RecipeMobileRecipeList',
  components: {
    [Cell.name]: Cell,
    [List.name]: List,
    [Tag.name]: Tag,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Icon.name]: Icon,
  },
  data: function () {
    return {
      loading: false,
      finished: false,
      page_count: 0,
      total_count: 0,
      page: 0,
      recipes: [],
      constants: constants,
      is_link: true,
      activeNames: ['1'],
      getTagType: function (recipe_type) {
        return {
          [constants.RECIPE_TYPE_MEAT]: 'primary',
          [constants.RECIPE_TYPE_VEGETABLE]: 'success',
          [constants.RECIPE_TYPE_SOUP]: 'warning',
        }[recipe_type];
      },
    };
  },
  mounted() {},
  methods: {
    onLoad() {
      this.getRecipes(this.page + 1);
      this.page += 1;
      if (this.page === this.page_count) {
        this.finished = true;
      }
    },
    getRecipes(page) {
      axios
        .get(config.publicPath + '/recipe/recipe/?page=' + page)
        .then(
          (response) => (
            (this.page_count = response.data.page_count),
            (this.total_count = response.data.count),
            this.recipes.push(...response.data.results),
            (this.page = page),
            (this.loading = false)
          )
        );
    },
  },
};
</script>

<style>
.recipe-type-tag {
  margin: 0 10px 0px 0px;
  padding-bottom: 0px;
}
.recipe-list {
  height: 50%;
}
</style>
