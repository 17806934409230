<template>
  <recipe_edit />
  <tabbar />
</template>

<script>
import tabbar from '@/components/recipe-mobile/tabbar.vue';
import recipe_edit from '@/components/recipe-mobile/recipe-edit.vue';

export default {
  name: 'RecipeMobileRecipeCreate',
  components: {
    tabbar,
    recipe_edit,
  },
  data: function() {
    return {};
  },
};
</script>
