<template>
  <el-table :data="recipes" max-height="500" class="recipe-table">
    <el-table-column prop="name" label="名字"> </el-table-column>
    <el-table-column
      prop="recipe_type"
      label="类型"
      :formatter="formatRecipeType"
      :filters="[
        { text: '青菜', value: 'vegetable' },
        { text: '肉', value: 'meat' },
        { text: '汤', value: 'soup' },
      ]"
      :filter-method="filterRecipeType"
    >
    </el-table-column>
    <el-table-column
      prop="difficulty"
      label="难度"
      :formatter="formatDifficulty"
      sortable
    >
    </el-table-column>
    <el-table-column prop="rate" label="评分" :formatter="formatRate" sortable>
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <el-button size="mini" @click="editRecipe(scope.row)">
          编辑
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    background
    small
    layout="total,prev, pager, next"
    hide-on-single-page
    @current-change="handleCurrentChange"
    v-model:current-page="current_page"
    :page-count="page_count"
    :total="total_count"
  >
  </el-pagination>
</template>

<script>
import axios from 'axios';
import router from '@/router/index';
import config from '@/config/index.js';

const type_map = {
  vegetable: '青菜',
  meat: '肉',
  soup: '汤',
};
const rate_map = {
  1: '🍚',
  2: '🍚 🍚',
  3: '🍚 🍚 🍚',
  4: '🍚 🍚 🍚 🍚',
  5: '🍚 🍚 🍚 🍚 🍚',
};
const difficulty_map = {
  1: '⭐',
  2: '⭐ ⭐',
  3: '⭐ ⭐ ⭐',
  4: '⭐ ⭐ ⭐ ⭐',
  5: '⭐ ⭐ ⭐ ⭐ ⭐',
};
function formatRecipeType(row) {
  return type_map[row.recipe_type];
}
function formatRate(row) {
  return rate_map[row.rate];
}
function formatDifficulty(row) {
  return difficulty_map[row.difficulty];
}
export default {
  name: 'RecipeList',
  // provide() {
  //   return {
  //     reload: this.reload,
  //   };
  // },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
    filterRecipeType(value, row) {
      return row.recipe_type === value;
    },
    editRecipe(row) {
      router.push({ name: 'RecipeDetail', params: { id: row.id } });
    },
    handleCurrentChange(val) {
      if (val === null) {
        return;
      }
      console.log(`当前页: ${val}`);
      axios
        .get(config.publicPath + '/recipe/recipe/' + '?page=' + val)
        .then(
          (response) => (
            (this.page_count = response.data.page_count),
            (this.total_count = response.data.count),
            (this.recipes = response.data.results)
          )
        );
    },
  },
  data: function() {
    return {
      recipes: [],
      formatRecipeType,
      formatRate,
      formatDifficulty,
      isRouterAlive: true,
      page_count: 1,
      total_count: 0,
      current_page: 1,
    };
  },
  mounted() {
    axios
      .get(config.publicPath + '/recipe/recipe/')
      .then(
        (response) => (
          (this.page_count = response.data.page_count),
          (this.total_count = response.data.count),
          (this.recipes = response.data.results)
        )
      );
  },
};
</script>

<style>
.el-pagination {
  margin: 10px 0 0 0;
}
</style>
