<template>
  <recipe_edit :recipe-id="recipe.id" :recipe_="recipe" />
  <tabbar />
</template>

<script>
import axios from 'axios';
import recipe_edit from '@/components/recipe-mobile/recipe-edit.vue';
import config from '@/config/index';
import tabbar from '@/components/recipe-mobile/tabbar.vue';

export default {
  components: {
    recipe_edit,
    tabbar,
  },
  data() {
    return {
      recipe: {},
    };
  },
  mounted() {
    axios
      .get(config.publicPath + '/recipe/recipe/' + this.$route.params.id + '/')
      .then((response) => (this.recipe = response.data));
  },
};
</script>
