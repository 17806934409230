<template>
  <el-container>
    <el-header>
      <el-menu mode="horizontal" default-active="#" router>
        <el-menu-item index="/"> 首页 </el-menu-item>
        <el-menu-item index="/week-recipe/"> 每周菜谱 </el-menu-item>
        <el-menu-item index="#">
          {{ recipe.name }}
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-main>
      <el-row justify="center">
        <el-col>
          <input_recipe :recipe-id="recipe.id" :recipe_="recipe"></input_recipe>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import axios from 'axios';
import 'element-plus/dist/index.css';
import router from '@/router/index';
import input_recipe from '@/components/input_recipe.vue';
import constants from '@/utils/constants.js';
import config from '@/config/index';

export default {
  name: 'RecipeDetail',
  components: { input_recipe },
  data: function () {
    return {
      recipe: {},
      constants: constants,
    };
  },
  mounted() {
    axios
      .get(config.publicPath + '/recipe/recipe/' + this.$route.params.id + '/')
      .then((response) => (this.recipe = response.data));
  },
  methods: {
    goHome() {
      router.push({ name: 'Home' });
    },
  },
};
</script>

<style>
.content {
  padding: 20px 10px;
}
</style>
