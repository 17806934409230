<template>
  <el-container>
    <el-header>
      <el-menu mode="horizontal" default-active="/week-recipe/" router>
        <el-menu-item index="/">
          首页
        </el-menu-item>
        <el-menu-item index="/week-recipe/">
          每周菜谱
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-main>
      <el-row justify="center">
        <el-col>
          <week_recipe></week_recipe>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import week_recipe from '@/components/week_recipe.vue';
export default {
  name: 'WeekRecipe',
  components: { week_recipe },
  data: function() {
    return {};
  },
};
</script>

<style></style>
