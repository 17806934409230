<template>
  <el-row justify="center">
    <el-col>
      <el-table border stripe :data="daily_recipes" max-height="500">
        <el-table-column label="日期">
          <template #default="scope">
            {{ formatDate(scope.row, scope.column, scope.row.date) }}
            <el-tag
              v-if:="isToday(scope.row.date)"
              :key="scope"
              size="small"
              type="danger"
              effect="dark"
              >今天</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="meat" label="肉">
          <template #default="scope">
            <el-tag
              id="meal"
              v-for="recipe in scope.row.meat"
              :key="recipe.name"
              size="small"
            >
              <router-link
                :to="{ name: 'RecipeDetail', params: { id: recipe.id } }"
                >{{ recipe.name }}</router-link
              >
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="vegetable" label="菜">
          <template #default="scope">
            <el-tag
              id="meal"
              v-for="recipe in scope.row.vegetable"
              :key="recipe.name"
              size="small"
            >
              <router-link
                :to="{ name: 'RecipeDetail', params: { id: recipe.id } }"
                >{{ recipe.name }}</router-link
              >
            </el-tag>
          </template></el-table-column
        >
        <el-table-column prop="soup" label="汤">
          <template #default="scope">
            <el-tag
              id="meal"
              v-for="recipe in scope.row.soup"
              :key="recipe.name"
              size="small"
            >
              <router-link
                :to="{ name: 'RecipeDetail', params: { id: recipe.id } }"
                >{{ recipe.name }}</router-link
              >
            </el-tag>
          </template></el-table-column
        >
        <el-table-column label="操作" width="80px">
          <template #default="scope">
            <el-button size="mini" @click="editDailyRecipe(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col>
      <el-button
        type="primary"
        plain
        class="re-generate"
        @click="reGenerateRecipe()"
        >重新生成</el-button
      >
    </el-col>
  </el-row>
</template>

<script>
import axios from 'axios';
import router from '@/router/index';
import config from '@/config/index';

export default {
  name: 'WeekRecipe',
  data: function() {
    return {
      daily_recipes: [],
    };
  },
  mounted() {
    console.log(config.publicPath);
    console.log(process.env);
    axios
      .get(config.publicPath + '/recipe/week-recipe/')
      .then((response) => (this.daily_recipes = response.data));
  },
  methods: {
    isToday(date) {
      const today = new Date();
      var date_ = new Date(date * 1000);
      if (
        date_.getDate() == today.getDate() &&
        date_.getMonth() == today.getMonth() &&
        date_.getFullYear() == today.getFullYear()
      ) {
        return true;
      }
      return false;
    },
    reGenerateRecipe() {
      axios
        .post(config.publicPath + '/recipe/week-recipe/')
        .then((response) => {
          response;
          return axios.get(config.publicPath + '/recipe/week-recipe/');
        })
        .then((response) => (this.daily_recipes = response.data));
    },
    editDailyRecipe(row) {
      router.push({ name: 'DailyRecipeDetail', params: { id: row.id } });
    },
    formatDate(row, column, cellValue) {
      if (cellValue === undefined) {
        return;
      }
      var date_ = new Date(cellValue * 1000);
      var days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
      return days[date_.getDay()];
    },
  },
};
</script>

<style>
.re-generate {
  margin: 20px 0;
  width: 100%;
}

.el-tag#meal a:link {
  text-decoration: none;
}
.el-tag#meal a:visited {
  text-decoration: none;
}
.el-tag#meal a:hover {
  text-decoration: underline;
}
.el-tag#meal a:active {
  text-decoration: underline;
}
.el-tag {
  margin: 0px 5px 0 0px;
}
.el-tag + .el-tag {
  margin: 5px 0px 0 0px;
}
</style>
