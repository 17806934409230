<template>
  <van-cell-group
    inset
    v-for:="recipe_type in constants.RECIPE_TYPES"
    :title="recipe_type.value"
  >
    <van-cell
      v-for:="recipe in daily_recipe[recipe_type.key]"
      :title="recipe.name"
    >
      <template #right-icon>
        <van-icon
          name="cross"
          class="delete-icon"
          @click="deleteRecipe(recipe)"
          color="#969799"
        />
      </template>
    </van-cell>
    <van-cell>
      <template #value>
        <van-button
          plain
          hairline
          block
          icon="plus"
          color="#969799"
          size="small"
          @click="addRecipe(recipe_type.key)"
        />
      </template>
    </van-cell>
  </van-cell-group>
  <div class="recipe-create">
    <van-button
      type="primary"
      round
      hairline
      plain
      block
      :disabled="disable_submit"
      @click="submitRecipe"
      >保存</van-button
    >
  </div>

  <tabbar />

  <van-popup
    v-model:show="show_picker"
    round
    position="bottom"
    safe-area-inset-bottom
  >
    <van-picker
      ref="picker"
      :columns="picker_columns[picker_recipe_type]"
      @cancel="show_picker = false"
      @confirm="onConfirm"
    />
  </van-popup>
</template>

<script>
import tabbar from '@/components/recipe-mobile/tabbar.vue';
import axios from 'axios';
import config from '@/config/index';
import constants from '@/utils/constants.js';

import {
  Picker,
  Icon,
  Button,
  Cell,
  CellGroup,
  Col,
  Grid,
  GridItem,
  PullRefresh,
  Row,
  SwipeCell,
  Popup,
} from 'vant';
export default {
  components: {
    [Icon.name]: Icon,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Col.name]: Col,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [PullRefresh.name]: PullRefresh,
    [Row.name]: Row,
    [SwipeCell.name]: SwipeCell,
    tabbar,
  },
  data() {
    return {
      daily_recipe: {
        meat: [],
        vegetable: [],
        soup: [],
      },
      payload: {
        meat: [],
        vegetable: [],
        soup: [],
      },
      constants: constants,
      show_picker: false,
      picker_recipe_type: null,
      picker_columns: {},
      disable_submit: true,
    };
  },
  mounted() {
    axios
      .get(config.publicPath + '/recipe/daily-recipe/' + this.$route.params.id)
      .then(
        (response) => (this.daily_recipe = this.serializeData(response.data))
      );
    for (let i = 0; i < constants.RECIPE_TYPES.length; i++) {
      axios
        .get(
          config.publicPath +
            '/recipe/recipe/?recipe_type=' +
            constants.RECIPE_TYPES[i]['key'] +
            '&page_size=500'
        )
        .then(
          (response) =>
            (this.picker_columns[
              constants.RECIPE_TYPES[i]['key']
            ] = this.serializeDataForPicker(response.data.results))
        );
    }
  },
  methods: {
    serializeData(data) {
      let data_ = {};
      data_['id'] = data['id'];
      data_['date'] = data['date'];
      data_['meat'] = [];
      data_['vegetable'] = [];
      data_['soup'] = [];
      for (let i = 0; i < data['recipes'].length; i++) {
        if (data['recipes'][i]['recipe_type'] == constants.RECIPE_TYPE_MEAT) {
          data_.meat.push(data['recipes'][i]);
          this.payload[data['recipes'][i]['recipe_type']].push(
            data['recipes'][i]['id']
          );
        } else if (
          data['recipes'][i]['recipe_type'] == constants.RECIPE_TYPE_VEGETABLE
        ) {
          data_.vegetable.push(data['recipes'][i]);
          this.payload[data['recipes'][i]['recipe_type']].push(
            data['recipes'][i]['id']
          );
        } else if (
          data['recipes'][i]['recipe_type'] == constants.RECIPE_TYPE_SOUP
        ) {
          data_.soup.push(data['recipes'][i]);
          this.payload[data['recipes'][i]['recipe_type']].push(
            data['recipes'][i]['id']
          );
        }
      }
      return data_;
    },
    serializeDataForPicker(data) {
      let data_ = [];
      for (let i = 0; i < data.length; i++) {
        data_.push({ value: data[i]['id'], text: data[i]['name'] });
      }
      return data_;
    },
    deleteRecipe(recipe) {
      for (const recipe_type of ['meat', 'vegetable', 'soup']) {
        for (let i = 0; i < this.daily_recipe[recipe_type].length; i++) {
          if (this.daily_recipe[recipe_type][i] == recipe) {
            this.daily_recipe[recipe_type].splice(i, 1);
            var index = this.payload[recipe_type].indexOf(recipe.id);
            this.payload[recipe_type].splice(index, 1);
            console.log(222, this.payload[recipe_type]);
            this.disable_submit = false;
            return;
          }
        }
      }
    },
    addRecipe(recipe_type) {
      this.show_picker = true;
      this.picker_recipe_type = recipe_type;
    },
    onConfirm(val) {
      this.show_picker = false;
      this.payload[this.picker_recipe_type].push(val.value);
      this.daily_recipe[this.picker_recipe_type].push({
        name: val.text,
        id: val.value,
      });
      this.disable_submit = false;
    },
    submitRecipe() {
      axios.put(
        config.publicPath + '/recipe/daily-recipe/' + this.$route.params.id,
        this.payload
      );
      this.disable_submit = true;
    },
  },
};
</script>

<style>
.daily_recipe {
  margin-top: 20px;
}
.delete-button {
  width: 100%;
  height: 100%;
}
.delete-icon {
  font-size: 16px;
  line-height: inherit;
  padding: 0 5px;
}
</style>
