import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import installElementPlus from './plugins/element'
import ElementPlus from 'element-plus'
// import 'element-plus/lib/theme-chalk/index.css'
// import Vant from 'vant';
// import 'vant/lib/index.css';
import router from './router'

const app = createApp(App)
installElementPlus(app)
app.use(ElementPlus)
// app.use(Vant)
app.use(router).mount('#app')
