<template>
  <van-form @submit="onSubmit(recipe_id)" class="recipe-create-form">
    <van-cell-group class="recipe-create" inset border>
      <van-field
        v-model="form.name"
        name="name"
        label="菜名"
        placehoder="鱼香茄子..."
        :rules="[{ required: true, message: '请填写菜名' }]"
      />
      <van-field name="reicpe_type" label="类型">
        <template #input>
          <van-radio-group v-model="form.recipe_type" direction="horizontal">
            <van-radio name="meat" shape="square">肉</van-radio>
            <van-radio name="vegetable" shape="square">菜</van-radio>
            <van-radio name="soup" shape="square">汤</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="rate" label="评分">
        <template #input>
          <van-rate
            v-model="form.rate"
            void-icon="like-o"
            icon="like"
          ></van-rate>
        </template>
      </van-field>
      <van-field name="difficulty" label="难度">
        <template #input>
          <van-rate v-model="form.difficulty" color="#ffd21e"></van-rate>
        </template>
      </van-field>
    </van-cell-group>

    <van-cell-group class="recipe-create" inset border>
      <van-field
        v-model="form.note"
        rows="2"
        auto-size
        label="备注"
        type="textarea"
      />
    </van-cell-group>

    <van-cell-group
      class="recipe-create"
      inset
      border
      v-if="form.recipe_ingredients && form.recipe_ingredients.length > 0"
      title="数量为 0 的食材将被删除"
    >
      <van-cell
        v-bind:title="recipe_ingredient.ingredient.name"
        v-for="recipe_ingredient in form.recipe_ingredients"
        :key="recipe_ingredient.id"
      >
        <template #value>
          <van-stepper
            v-model.number="recipe_ingredient.quantity"
            default-value="recipe_ingredient.quantity"
            :decimal-length="1"
            allow-empty="true"
            min="0"
          />
          {{ recipe_ingredient.ingredient.unit }}
        </template>
      </van-cell>
    </van-cell-group>

    <div class="recipe-create">
      <van-row gutter="20">
        <van-col span="8">
          <van-button
            icon="plus"
            type="warning"
            plain
            round
            hairline
            class="submit-button"
            @click="showIngredientDialog = true"
            >创建食材</van-button
          >
        </van-col>
        <van-col span="16">
          <van-button
            icon="plus"
            type="primary"
            plain
            round
            hairline
            class="submit-button"
            @click="showIngredientPicker = true"
            >添加食材</van-button
          >
        </van-col>
      </van-row>
    </div>

    <van-popup v-model:show="showIngredientPicker" round position="bottom">
      <van-picker
        :columns="ingredient_columns"
        :columns-field-names="customFieldName"
        @cancel="showIngredientPicker = false"
        @confirm="onConfirmIngredient"
      />
    </van-popup>

    <van-dialog
      v-model:show="showIngredientDialog"
      title="创建食材"
      show-cancel-button
      confirm-button-text="创建"
      @confirm="onCreateIngredient"
    >
      <van-cell-group inset border>
        <van-field
          v-model="ingredient_name"
          label="名称"
          name="ingredient_name"
          placeholder="葱、蒜、姜..."
          :rules="[{ required: true, message: '请填写食材名称' }]"
        />
        <van-field
          v-model="ingredient_unit"
          label="单位"
          name="ingredient_unit"
          placeholder="个、斤、千克..."
          :rules="[{ required: true, message: '请填写单位' }]"
        />
      </van-cell-group>
    </van-dialog>
    <div class="recipe-create">
      <van-row gutter="20">
        <van-col span="8" v-if="recipe_id">
          <van-button
            class="submit-button"
            round
            type="danger"
            hairline
            :disabled="disable_submit"
            @click="onSubmitDelete(recipe_id)"
            :loading="loading"
            >删除</van-button
          >
        </van-col>
        <van-col span="16" v-if="recipe_id">
          <van-button
            class="submit-button"
            round
            type="primary"
            hairline
            :disabled="disable_submit"
            @click="onSubmit(recipe_id)"
            :loading="loading"
            >提交</van-button
          >
        </van-col>
        <van-col span="24" v-else>
          <van-button
            class="submit-button"
            round
            type="primary"
            hairline
            :disabled="disable_submit"
            @click="onSubmit(recipe_id)"
            :loading="loading"
            >提交</van-button
          >
        </van-col>
      </van-row>
    </div>
  </van-form>
</template>

<script>
import {
  Button,
  Cell,
  CellGroup,
  Col,
  Dialog,
  Field,
  Form,
  Picker,
  Popup,
  Radio,
  RadioGroup,
  Rate,
  Row,
  Stepper,
  Toast,
} from 'vant';
import axios from 'axios';
import config from '@/config/index';
import constants from '@/utils/constants.js';
import router from '@/router/index';
import { ref } from 'vue';

export default {
  props: ['recipe_'],
  watch: {
    recipe_(val) {
      this.form = val;
      this.recipe_id = val.id;
    },
  },
  components: {
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Col.name]: Col,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Form.name]: Form,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Rate.name]: Rate,
    [Row.name]: Row,
    [Stepper.name]: Stepper,
  },
  data() {
    const customFieldName = {
      text: 'name',
    };
    const showIngredientPicker = ref(false);
    const showIngredientDialog = ref(false);
    const disable_submit = ref(false);

    return {
      form: {
        name: null,
        recipe_type: null,
        rate: 0,
        difficulty: 0,
        note: null,
        recipe_ingredients: null,
      },
      loading: false,
      disable_submit,
      recipe_id: null,
      ingredient_columns: null,
      customFieldName,
      showIngredientPicker,
      showIngredientDialog,
      ingredient_name: null,
      ingredient_unit: null,
    };
  },
  mounted() {
    this.getIngredients();
  },
  methods: {
    onSubmit(recipe_id) {
      if (!this.form.name) {
        return;
      }
      this.loading = true;
      let data = {
        name: this.form.name,
        recipe_type: this.form.recipe_type
          ? this.form.recipe_type
          : constants.RECIPE_TYPE_MEAT,
        difficulty: this.form.difficulty,
        rate: this.form.rate,
        note: this.form.note ? this.form.note : null,
        recipe_ingredients: this.form.recipe_ingredients
          ? this.form.recipe_ingredients
          : [],
      };
      if (!recipe_id) {
        axios
          .post(config.publicPath + '/recipe/recipe/', data)
          .then(
            (response) => (response, router.push({ name: 'RecipeMobileHome' }))
          );
      } else {
        axios
          .put(config.publicPath + '/recipe/recipe/' + recipe_id + '/', data)
          .then(
            (response) => (
              response,
              Toast.success({
                message: '修改成功',
                forbidClick: true,
                duration: 500,
              }),
              (this.loading = false)
            )
          );
      }
    },
    onSubmitDelete(recipe_id) {
      if (!this.form.name) {
        return;
      }
      this.loading = true;
      axios
        .delete(config.publicPath + '/recipe/recipe/' + recipe_id + '/')
        .then(
          (Toast.success({
            message: '删除成功',
            forbidClick: true,
            duration: 500,
          }),
          (this.loading = false))
        );
    },
    getIngredients() {
      axios.get(config.publicPath + '/recipe/ingredient/').then((response) => {
        this.ingredient_columns = response.data['results'];
      });
    },
    onConfirmIngredient(value) {
      var exists = false;
      this.showIngredientPicker = false;
      if (this.form.recipe_ingredients === null) {
        this.form.recipe_ingredients = [];
      }
      for (let i = 0; i < this.form.recipe_ingredients.length; i++) {
        if (this.form.recipe_ingredients[i].ingredient.id == value.id) {
          exists = true;
          break;
        }
      }
      if (exists) {
        Toast.fail({
          message: '该食材已存在',
          forbidClick: true,
          duration: 800,
        });
      } else {
        this.form.recipe_ingredients.push({
          id: 0,
          ingredient: {
            id: value.id,
            name: value.name,
            unit: value.unit,
          }, // 添加的食材
        });
      }
    },
    onCreateIngredient() {
      console.log(
        'onCreateIngredient',
        this.ingredient_name,
        this.ingredient_unit
      );
      if (!this.ingredient_name || !this.ingredient_unit) {
        Toast.fail({
          message: '请输入食材名称和单位',
          forbidClick: true,
          duration: 800,
        });
        return;
      }
      this.showIngredientDialog = false;
      for (let i = 0; i < this.ingredient_columns.length; i++) {
        if (this.ingredient_columns[i].name == this.ingredient_name) {
          Toast.fail({
            message: '该食材已存在',
            forbidClick: true,
            duration: 800,
          });
          break;
        }
      }
      axios
        .post(config.publicPath + '/recipe/ingredient/', {
          name: this.ingredient_name,
          unit: this.ingredient_unit,
        })
        .then(
          (response) => (
            response,
            this.ingredient_columns.unshift({
              id: response.data.id,
              name: this.ingredient_name,
              unit: this.ingredient_unit,
            }),
            Toast.success({
              message: '创建成功',
              forbidClick: true,
              duration: 800,
            }),
            (this.ingredient_name = null),
            (this.ingredient_unit = null)
          )
        );

      // this.ingredient_name = value.name;
      // this.ingredient_unit = value.unit;
    },
  },
};
</script>
<style>
.recipe-create {
  margin: 20px 16px;
}
.submit-button {
  width: 100%;
}
</style>
