<template>
  <el-container>
    <el-header>
      <el-menu mode="horizontal" default-active="/" router>
        <el-menu-item index="/">
          首页
        </el-menu-item>
        <el-menu-item index="/week-recipe/">
          每周菜谱
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-main>
      <el-row justify="center">
        <el-col>
          <recipe_list></recipe_list>
        </el-col>
      </el-row>
      <el-row justify="center">
        <el-col>
          <input_recipe :recipe="{}"></input_recipe>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import input_recipe from '@/components/input_recipe.vue';
import recipe_list from '@/components/recipe_list.vue';

export default {
  name: 'Home',
  components: { input_recipe, recipe_list },
  data: function() {
    return {};
  },
};
</script>
<style>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
