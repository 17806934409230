import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/home.vue'
import RecipeDetail from '@/views/recipeDetail.vue'
import WeekRecipe from '@/views/weekRecipe.vue'
import DailyRecipeDetail from '@/views/dailyRecipeDetail.vue'
import RecipeMobileHome from '@/views/recipe-mobile/Home.vue'
import RecipeMobileRecipeCreate from '@/views/recipe-mobile/RecipeCreate.vue'
import RecipeMobileWeekRecipe from '@/views/recipe-mobile/WeekRecipe.vue'
import RecipeMobileRecipeDetail from '@/views/recipe-mobile/RecipeDetail.vue'
import RecipeMobileDailyRecipeDetail from '@/views/recipe-mobile/DailyRecipeDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/recipe/:id',
    name: "RecipeDetail",
    component: RecipeDetail
  },
  {
    path: '/week-recipe/',
    name: "WeekRecipe",
    component: WeekRecipe
  },
  {
    path: '/daily-recipe/:id',
    name: "DailyRecipeDetail",
    component: DailyRecipeDetail
  },

  // mobile recipe
  {
    path: '/recipe-mobile/',
    name: "RecipeMobileHome",
    component: RecipeMobileHome
  },
  {
    path: '/recipe-mobile/recipe/create/',
    name: "RecipeMobileRecipeCreate",
    component: RecipeMobileRecipeCreate,
    meta: {title: '创建菜谱'}
  },
  {
    path: '/recipe-mobile/week-recipe/',
    name: "RecipeMobileWeekRecipe",
    component: RecipeMobileWeekRecipe,
    meta: {title: '每周菜谱'}
  },
  {
    path: '/recipe-mobile/recipe/:id',
    name: "RecipeMobileRecipeDetail",
    component: RecipeMobileRecipeDetail,
    meta: {title: '菜谱'}
  },
  {
    path: '/recipe-mobile/daily-recipe/:id',
    name: "RecipeMobileDailyRecipeDetail",
    component: RecipeMobileDailyRecipeDetail,
    meta: {title: '每日菜谱'}
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const defaultTitle = '今天吃什么';
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  next();
});

export default router;
