<template>
  <!-- <van-pull-refresh
    v-model="loading"
    @refresh="onRefresh"
    pulling-text="下拉重新生成每周菜谱"
    loosing-text="可以松手了..."
    pull-distance="100"
    :head-height="100"
  > -->
  <div v-for:="(daily_recipe, recipe_index) in daily_recipes">
    <van-cell-group inset class="daily_recipes">
      <template #title>
        <span> {{ formatDate(daily_recipe.date) }}</span>
        <van-tag
          type="danger"
          class="today-tag"
          v-if="isToday(daily_recipe.date)"
          >今天</van-tag
        >
      </template>
      <van-swipe-cell>
        <van-row v-if="daily_recipe.meat">
          <van-col span="3" class="recipe_type">
            <van-grid :column-num="1">
              <van-grid-item text="肉" class="recipe_type"></van-grid-item>
            </van-grid>
          </van-col>
          <van-col span="21">
            <van-grid :column-num="3" clickable>
              <van-grid-item
                v-for:="recipe in daily_recipe.meat"
                :text="recipe.name"
                :to="{
                  name: 'RecipeMobileRecipeDetail',
                  params: { id: recipe.id },
                }"
              />
              <van-grid-item
                v-for="value in 3 - (daily_recipe.meat.length % 3)"
                :key="value"
              />
            </van-grid>
          </van-col>
        </van-row>
        <van-row v-if="daily_recipe.vegetable">
          <van-col span="3" class="recipe_type">
            <van-grid :column-num="1">
              <van-grid-item text="菜" class="recipe_type"></van-grid-item>
            </van-grid>
          </van-col>
          <van-col span="21">
            <van-grid :column-num="3" clickable>
              <van-grid-item
                v-for:="recipe in daily_recipe.vegetable"
                :text="recipe.name"
                :to="{
                  name: 'RecipeMobileRecipeDetail',
                  params: { id: recipe.id },
                }"
              />
              <van-grid-item
                v-for="value in 3 - (daily_recipe.vegetable.length % 3)"
                :key="value"
              />
            </van-grid>
          </van-col>
        </van-row>
        <van-row
          v-if="daily_recipe.soup !== undefined && daily_recipe.soup.length > 0"
        >
          <van-col span="3" class="recipe_type">
            <van-grid :column-num="1">
              <van-grid-item text="汤" class="recipe_type"></van-grid-item>
            </van-grid>
          </van-col>
          <van-col span="21">
            <van-grid :column-num="1" clickable>
              <van-grid-item
                v-for:="recipe in daily_recipe.soup"
                :text="recipe.name"
                :to="{
                  name: 'RecipeMobileRecipeDetail',
                  params: { id: recipe.id },
                }"
              />
            </van-grid>
          </van-col>
        </van-row>
        <template #right>
          <van-button
            square
            text="编辑"
            type="primary"
            class="action-button"
            :to="{
              name: 'RecipeMobileDailyRecipeDetail',
              params: { id: daily_recipe.id },
            }"
          />
          <van-button
            square
            text="刷新"
            type="danger"
            class="action-button"
            @click="reGenerateRecipe(daily_recipe.id, recipe_index)"
          />
        </template>
      </van-swipe-cell>
    </van-cell-group>
  </div>
  <!-- </van-pull-refresh> -->
  <van-sticky :offset-bottom="60" position="bottom" class="refresh-button">
    <van-button
      type="primary"
      color="linear-gradient(to right, #ff6034, #ee0a24)"
      hairline
      round
      @click="onRefresh"
      >刷新</van-button
    >
  </van-sticky>
</template>

<script>
import {
  Button,
  Cell,
  CellGroup,
  Col,
  Grid,
  GridItem,
  PullRefresh,
  Row,
  SwipeCell,
  Tag,
  Sticky,
} from 'vant';
import axios from 'axios';
import config from '@/config/index';

export default {
  name: 'RecipeMobileWeekRecipe',
  components: {
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Col.name]: Col,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [PullRefresh.name]: PullRefresh,
    [Row.name]: Row,
    [SwipeCell.name]: SwipeCell,
    [Sticky.name]: Sticky,
  },
  data() {
    return {
      daily_recipes: [],
      loading: false,
    };
  },
  mounted() {
    this.getWeekRecipe(false);
  },
  methods: {
    isToday(date) {
      const today = new Date();
      var date_ = new Date(date * 1000);
      if (
        date_.getDate() == today.getDate() &&
        date_.getMonth() == today.getMonth() &&
        date_.getFullYear() == today.getFullYear()
      ) {
        return true;
      }
      return false;
    },
    formatDate(date) {
      if (date === undefined) {
        return;
      }
      var date_ = new Date(date * 1000);
      var days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
      var date_str = days[date_.getDay()];
      if (this.isToday(date_)) {
        date_str += ' 🌟';
      }
      return date_str;
    },
    onRefresh() {
      this.getWeekRecipe(true);
    },
    getWeekRecipe(regen) {
      if (regen) {
        axios
          .post(config.publicPath + '/recipe/week-recipe/')
          .then(
            (response) => (
              (this.daily_recipes = response.data), (this.loading = false)
            )
          );
      } else {
        axios
          .get(config.publicPath + '/recipe/week-recipe/')
          .then(
            (response) => (this.daily_recipes = response.data),
            (this.loading = false)
          );
      }
    },
    reGenerateRecipe(daily_recipe_id, recepe_index) {
      axios
        .post(config.publicPath + '/recipe/daily-recipe/' + daily_recipe_id)
        .then((response) =>
          this.daily_recipes.splice(recepe_index, 1, response.data)
        );
    },
  },
};
</script>

<style>
.recipe_type .van-grid-item__content {
  background-color: #f2f3f5;
}
.recipe_type {
  background-color: #f2f3f5;
}
.daily_recipes {
  margin-bottom: 10px;
}
.daily_recipes:last-child {
  margin-bottom: 40px;
}
.action-button {
  height: 100%;
  width: 50%;
}
.today-tag {
  margin-left: 10px;
}
.refresh-button {
  float: right;
  margin-right: 20px;
}
</style>
