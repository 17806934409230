<template>
  <recipe_list />
  <tabbar />
</template>

<script>
import recipe_list from '@/components/recipe-mobile/recipe-list.vue';
import tabbar from '@/components/recipe-mobile/tabbar.vue';

export default {
  name: 'RecipeMobileHome',
  components: {
    tabbar,
    recipe_list,
  },
  data: function() {
    return {};
  },
};
</script>

<style>
body {
  background-color: #f7f8fa;
}
</style>
