<template>
  <van-tabbar route fixed placeholder class="recipe-tabar">
    <van-tabbar-item to="/recipe-mobile/week-recipe/" icon="calendar-o">
      每周菜谱
    </van-tabbar-item>
    <van-tabbar-item to="/recipe-mobile/" icon="orders-o">
      菜谱列表
    </van-tabbar-item>
    <van-tabbar-item to="/recipe-mobile/recipe/create/" icon="records">
      创建菜谱
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';

export default {
  name: 'RecipeMobileTabbar',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data: function() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
