<template>
  <el-container>
    <el-header>
      <el-menu mode="horizontal" router default-active="#">
        <el-menu-item index="/">
          首页
        </el-menu-item>
        <el-menu-item index="/week-recipe/">
          每周菜谱
        </el-menu-item>
        <el-menu-item index="#">
          每日菜谱
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-main>
      <el-row justify="center">
        <el-col> <daily_recipe_detail></daily_recipe_detail></el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import daily_recipe_detail from '@/components/daily_recipe_detail.vue';
export default {
  name: 'DailyRecipeDetail',
  components: { daily_recipe_detail },
  data: function() {
    return {};
  },
};
</script>
