<template>
  <el-row justify="left" gutter="10">
    <el-col>
      <el-form :rules="rules" ref="form" :model="form" label-position="left">
        <el-form-item label="名字" prop="name">
          <el-input v-model="form.name" placeholder="鱼香茄子......"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="类型">
              <el-select
                v-model="form.recipe_type"
                placeholder="选择类型"
                style="width: 100%"
              >
                <el-option label="肉" value="meat"></el-option>
                <el-option label="菜" value="vegetable"></el-option>
                <el-option label="汤" value="soup"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="评分">
              <el-select
                v-model="form.rate"
                placeholder="选择评分"
                style="width: 100%"
              >
                <el-option label="再也不吃了" value="1"></el-option>
                <el-option label="难吃，再练练" value="2"></el-option>
                <el-option label="还行" value="3"></el-option>
                <el-option label="好吃" value="4"></el-option>
                <el-option label="上天了" value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="难度">
              <el-select
                v-model="form.difficulty"
                placeholder="选择难度"
                style="width: 100%"
              >
                <el-option label="无脑做" value="1"></el-option>
                <el-option label="还行" value="2"></el-option>
                <el-option label="有点麻烦" value="3"></el-option>
                <el-option label="要花心机" value="4"></el-option>
                <el-option label="做不来做不来" value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.note"></el-input>
        </el-form-item>
        <el-form-item>
          <el-col :span="8" v-if="recipe_id">
            <el-button
              type="danger"
              plain
              class="summit-recipe"
              @click="onSubmitDelete(recipe_id)"
              >删除</el-button
            >
          </el-col>
          <el-col :span="16" v-if="recipe_id">
            <el-button
              type="primary"
              plain
              class="summit-recipe"
              @click="onSubmit(recipe_id)"
              >提交</el-button
            >
          </el-col>
          <el-col :span="24" v-else>
            <el-button
              type="primary"
              plain
              class="summit-recipe"
              @click="onSubmit(recipe_id)"
              >提交</el-button
            >
          </el-col>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import axios from 'axios';
import config from '@/config/index';
import { ElMessage } from 'element-plus';

export default {
  props: ['recipe_'],
  watch: {
    recipe_(val) {
      this.form = val;
      this.recipe_id = val.id;
    },
  },
  data: function () {
    return {
      form: {
        name: null,
        recipe_type: null,
        difficulty: null,
        rate: null,
        note: null,
      },
      rules: {
        name: [{ required: true, trigger: 'blur', message: '输入菜名' }],
      },
      recipe_id: null,
    };
  },
  methods: {
    onSubmit(recipe_id) {
      let data = {
        name: this.form.name,
        recipe_type: this.form.recipe_type,
        difficulty: this.form.difficulty,
        rate: this.form.rate,
        note: this.form.note,
      };
      if (!recipe_id) {
        axios
          .post(config.publicPath + '/recipe/recipe/', data)
          .then(function () {
            ElMessage({
              message: '创建成功.',
              type: 'success',
            });
            location.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .put(config.publicPath + '/recipe/recipe/' + recipe_id + '/', data)
          .then(function () {
            ElMessage({
              message: '修改成功.',
              type: 'success',
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onSubmitDelete(recipe_id) {
      axios
        .delete(config.publicPath + '/recipe/recipe/' + recipe_id + '/')
        .then(function () {
          ElMessage.error('删除成功.');
          location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.summit-recipe {
  width: 100%;
  margin-bottom: 10px;
}
</style>
